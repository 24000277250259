import React from "react";
import PropTypes from "prop-types";
import "prismjs/themes/prism-okaidia.css";
import Headline from "../Article/Headline";
import Bodytext from "../Article/Bodytext";
import Meta from "./Meta";
import Author from "./Author";
import NextPrev from "./NextPrev";
import Comments from "./Comments";

class Post extends React.Component {

   constructor(props) {
    super(props)
   }
   
   componentDidMount() {

   }

   render() {

        const {
        post,
        post: {
          html,
          htmlAst,
          fields: { prefix, slug },
          frontmatter: { title, author, tags },
          parent: { modifiedTime }
        },
        authornote,
        next: nextPost,
        prev: prevPost,
        theme
      } = this.props;

      return (
        <React.Fragment>
          <header>
            <Headline title={title} theme={theme} />
            <Meta prefix={prefix} lastEdit={modifiedTime} author={author} tags={tags} theme={theme} />
          </header>
          <Bodytext content={post} theme={theme} />

          <footer>
             <Author note={authornote} theme={theme} />
             {/* <NextPrev next={nextPost} prev={prevPost} theme={theme} /> */}
             <NextPrev next={prevPost} prev={nextPost} theme={theme} />
          </footer>
          <Comments/>
         

        </React.Fragment>
      );

   }
}


Post.propTypes = {
  post: PropTypes.object.isRequired,
  authornote: PropTypes.string.isRequired,
  next: PropTypes.object,
  prev: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default Post;
