import React from "react";


const commentAreaID = 'commentArea';

class Comments extends React.Component {


   constructor(props) {
      super(props)
   }

   componentDidMount() {

    // setting 100ms sec delay as sometime meta tag with og:title does not load
    // will fix this issue later 
    setTimeout(()=>{
      //for comments
      const script = document.createElement("script");
      script.src = "https://utteranc.es/client.js";
      script.setAttribute('issue-term', 'og:title');
      script.async = true;
      script.setAttribute("repo","anshul-srivastava/anshul-srivastava.github.io")
      script.setAttribute('theme',"github-light");
      script.setAttribute('crossorigin', "anonymous");
      document.getElementById(commentAreaID).appendChild(script);
    }, 100);
   	  

   }


   render() {
   	 return <div id={commentAreaID}></div>;
   }

}

export default Comments;